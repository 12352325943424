import { template as template_acac0a92c3ab44fd9bba22ecff0d678b } from "@ember/template-compiler";
const SidebarSectionMessage = template_acac0a92c3ab44fd9bba22ecff0d678b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
