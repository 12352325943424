import { template as template_1d85f6bce8ea495b8ae7d69ba095c05f } from "@ember/template-compiler";
const FKControlMenuContainer = template_1d85f6bce8ea495b8ae7d69ba095c05f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
