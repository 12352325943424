import { template as template_fcc243758adc40c188fdde6fec5c39fa } from "@ember/template-compiler";
const FKText = template_fcc243758adc40c188fdde6fec5c39fa(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
