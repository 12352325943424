import { template as template_3758a630f2bd4c23834abf1146827b76 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_3758a630f2bd4c23834abf1146827b76(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
